import { classnames } from "@vgno/utils";

import { Logo } from "./shared/Logo";
import { formatHoursAndMinutes } from "./shared/formatHoursAndMinutes";

import type { Article } from "@vgno/article";

import styles from "./TeamEventStatus.module.css";

export const TeamEventStatus = ({
  awayTeamLogoUrl,
  awayTeamResult,
  eventName,
  homeTeamLogoUrl,
  homeTeamResult,
  startDate,
  statusType,
}: TeamEventStatusProps) => {
  if (
    !eventName ||
    !startDate ||
    !statusType ||
    statusType === "finished" ||
    !homeTeamLogoUrl ||
    homeTeamResult === undefined ||
    !awayTeamLogoUrl ||
    awayTeamResult === undefined
  ) {
    return null;
  }

  const [homeTeamShortName, awayTeamShortName] = eventName.split(" - ");

  const centralText =
    statusType === "inProgress"
      ? `${homeTeamResult} - ${awayTeamResult}`
      : formatHoursAndMinutes(new Date(startDate));

  return (
    <div className={classnames(styles.teamEventStatus, styles[statusType])}>
      <Logo alt="" url={homeTeamLogoUrl} />
      <div>{homeTeamShortName}</div>
      <div className={styles.centralText}>{centralText}</div>
      <div>{awayTeamShortName}</div>
      <Logo alt="" url={awayTeamLogoUrl} />
    </div>
  );
};

type TeamEventStatusProps = {
  awayTeamLogoUrl?: string;
  awayTeamResult?: number;
  eventName?: string;
  homeTeamLogoUrl?: string;
  homeTeamResult?: number;
  startDate?: Article["customProperties"]["eventStartDate"];
  statusType?: Article["customProperties"]["eventStatusType"];
};

import { classnames } from "@vgno/utils";

import { F1EventStatus } from "./F1EventStatus";
import { SkiEventStatus } from "./SkiEventStatus";
import { TeamEventStatus } from "./TeamEventStatus";

import type { Article } from "@vgno/article";

import styles from "./EventStatus.module.css";

export const EventStatus = ({ teaserCustomProperties }: SportPillProps) => {
  const { eventStatusType, sport } = teaserCustomProperties;
  if (!sport || !eventStatusType) {
    return null;
  }

  return (
    <div className={classnames(styles.eventStatus, styles[eventStatusType])}>
      {(() => {
        switch (sport) {
          case "football":
          case "ice-hockey":
          case "handball":
            return (
              <TeamEventStatus
                awayTeamLogoUrl={teaserCustomProperties.eventAwayTeamLogoUrl}
                awayTeamResult={teaserCustomProperties.eventAwayTeamResult}
                eventName={teaserCustomProperties.eventName}
                homeTeamLogoUrl={teaserCustomProperties.eventHomeTeamLogoUrl}
                homeTeamResult={teaserCustomProperties.eventHomeTeamResult}
                startDate={teaserCustomProperties.eventStartDate}
                statusType={eventStatusType}
              />
            );
          case "formula1":
            return (
              <F1EventStatus
                eventName={teaserCustomProperties.eventName}
                eventVenueCountryFlagUrl={
                  teaserCustomProperties.eventVenueCountryFlagUrl
                }
                startDate={teaserCustomProperties.eventStartDate}
                statusType={eventStatusType}
              />
            );
          // F1 and ski sports look similar but there are too many differences to use the same component
          case "cross-country-skiing":
          case "biathlon":
            return (
              <SkiEventStatus
                eventName={teaserCustomProperties.eventName}
                eventVenueCountryFlagUrl={
                  teaserCustomProperties.eventVenueCountryFlagUrl
                }
                startDate={teaserCustomProperties.eventStartDate}
                statusType={eventStatusType}
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

type SportPillProps = {
  teaserCustomProperties: Article["customProperties"];
};

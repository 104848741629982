import { imageSizes, type TeaserProps } from "@vgno/teasers/Teaser";
import { getHotspot, getSrcSet } from "@vgno/utils";

import type { ImageAsset } from "vg";

import styles from "./TeaserImage.module.css";

export const TeaserImage = ({
  alt,
  imageAsset,
  loading,
  size,
}: TeaserImageProps) => {
  return (
    <picture className={styles.picture}>
      <img
        alt={alt}
        loading={loading}
        sizes={imageSizes[size]}
        src={imageAsset.urls[0].url}
        srcSet={getSrcSet(imageAsset)}
        style={{
          objectPosition: getHotspot(imageAsset),
        }}
      />
    </picture>
  );
};

type TeaserImageProps = {
  alt: string;
  imageAsset: ImageAsset;
  loading?: TeaserProps["loading"];
  size: Required<TeaserProps>["size"];
};

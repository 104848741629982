import styles from "./Logo.module.css";

export const Logo = ({ alt, url }: TeamInfoProps) => (
  <img alt={`${alt} logo`} className={styles.logo} src={url} />
);

type TeamInfoProps = {
  alt: string;
  url: string;
};
